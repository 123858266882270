.mint-background{
    /* background: url("./../../images/Green-poker-table-background-403821.JPG"); */
    /* background: url("./../../images/backgroundTable5-min.jpg"); */
    background-color: black;
    background-Position: 'center';
    background-size: 100% auto;
    /* background-Repeat: 'no-repeat'; */
    /* background-color: white; */
    /* height: 100vh; */
    width: auto;
    object-fit: contain;
    z-index: 4;
    position: relative;
    /* border-top: solid #505050 !important; */
}

.mint-background-inner{
    /* background: url("./../../images/backgroundTable5-min.jpg"); */
    background-color: rgb(37, 37, 37);
    background-Position: 'center';
    background-size: 100% auto;
    width: auto;
    object-fit: contain;
    z-index: 4;
    position: relative;
    border-top: solid #505050; 
    padding-bottom: 100px;
    overflow-x: hidden;
}

.home-container{
    overflow-x: hidden;
}

.right-mint{
    width: 100%;
}

@media screen and (max-width:655px) {
    .page-sections{
        display: block !important;
        padding-left: 0.5em !important;
        padding-right: 0.5em !important;    
    }

    .page-sections-home{
        display: block !important;
        padding-left: 0.5em !important;
        padding-right: 0.5em !important;    
    }

    .container2{
        object-fit: cover;
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100% !important;
    }

    .image-header{
        width:100%;
        height:auto !important;
        margin-top: 50px;
    }

    .img-container video{
        width: 300px !important;
    }

    .title{
        font-size: 25px !important;
        padding-top: 1em;
    }

    .text-holder h1{
        font-size: 20px !important;
    }

    .text-holder p{
        font-size: 15px !important;
    }

    .text-holder em{
        font-size: 18px !important;
    }

    .left{
        display: flex;
        justify-content: center;
    }
    
    .text-holder{
        width: 85%;
    }

    .img-container {
        z-index: 1;
    }

    .right-mint{
        display: block;
    }
}

@media screen and (max-width: 1050px) and (min-width: 655px) {
    .img-container video{
        width: 500px !important;
    }
    .page-sections{
        display: block !important;
        padding-left: 1em !important;
        padding-right: 1em !important;
    }
    .page-sections-home{
        display: block !important;
        padding-left: 1em !important;
        padding-right: 1em !important;
    }

    .text-holder h1{
        font-size: 17px !important;
    }

    .text-holder p{
        font-size: 15px !important;
    }

    .text-holder em{
        font-size: 15px !important;
    }

    .right{
        display: block !important;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1050px) {

    .text-holder h1{
        font-size: 20px !important;
    }

    .text-holder p{
        font-size: 15px !important;
    }

    .text-holder em{
        font-size: 15px !important;
    }
}

.fadeAboveFooterJunks{
    font-size: 50px;
    margin: 0 auto;
    font-weight: 400;
    color: #f1f1f1;
    /* font-family: 'poker'; */
    /* font-family:  Verdana, sans-serif; */
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0), #000000);
    height: 100px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.container2{
    object-fit: cover;
    position: relative;
    top: 0;
    left: 0;
}

.mint-background-overlay{
    /* margin-top: 25vh; */
    /* height: 100vh;
    background-color: rgba(0, 0, 0,);
    overflow: hidden; */
}

.page-contents{
    height: auto;
    width: auto;
    /* padding-top: 2vh; */
}


.title{
    font-size: 40px;
    margin: 0 auto;
    font-weight: 400;
    color: #f1f1f1;
    /* font-family: 'poker'; */
    /* font-family:  Verdana, sans-serif; */
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
    background: linear-gradient(#000000, rgba(255,255,255,0));
}

.page-sections-mint{
    height: 100%;
    width: auto;
    display: flex;
}

.page-contents-mint-mobile{
    width: auto;
    height:  auto;
    display: none;
}

.page-sections-home{
    height: 100%;
    width: auto;
    display: flex;
    padding-left: 7%;
    padding-top: 1%;
}

.left{
    flex: 50%;
    z-index: 2;
}

.text-holder{
    /* margin-top: 5vh; */
    border: #505050 solid;
    background-color: #0c0c0c;
    border-radius: 15px;
    margin-bottom: 5vh;
}

.text-holder h1{
    text-align: center;
    /* font-family: 'poker'; */
    /* font-family:  Verdana, sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 400;
    color: white;
    margin-bottom: 20px;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px;

}

.text-holder p{
    text-align: center;
    /* font-family: 'poker'; */
    /* font-family:  Verdana, sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    /* padding-left: 10vh; */
    color: white;
    position: relative;
    justify-content: center;
    /* display: flex; */
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;

}

.text-holder em{
    font-family: 'Roboto', sans-serif;
    color: rgb(201, 3, 3);
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
}

.right{
    flex: 50%;
    display: flex;
    align-items: center;
}

.right-mobile{
    display: none;
}

.img-container{
    position: relative;
    justify-content: center;
    display: flex;
    width: 100%;
    /* margin-bottom: 20px;
    z-index: 5; */
}

.img-container img{
    margin-bottom: 20px;
    border: #505050 solid;
    background-color: #0c0c0c;
    border-radius: 15px;
    /* z-index: 5; */
}

.img-container-overlay{
    padding-top: 25%;
    height: 10%;
    width: 62%;
    /* background-image: linear-gradient(180deg, rgba(117, 0, 0, 0) 50%, rgba(117, 0, 0, 0.4) 50%);     */
    position: absolute;
    z-index: 10;
}

.img-container-overlay h1{
    color: white;
    text-align: center;
    background-color: rgba(117, 0, 0, 0.6);
    border-radius: 10px;
}

.freeMint{
    padding-top: 25px;
    height: 100px;
}

.img-container img{
    cursor: pointer;
    position:relative;
    z-index: 5;
    width: 50%;
    border-radius: 20px;
}

.img-container video{
    /* cursor: pointer;
    left:20%;
    position:relative; */
    border: solid #505050; 
    border-radius: 20px;
}

.image-header{
    width:100%;
    height:250px;
}

.mintButtonContainer{
    display: flex;
    width: 100%;
    height: 70vh;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-image: linear-gradient(
        rgba(9,9,9,0) 1%,
        rgba(9,9,9,0.1) 10%,
        rgba(8, 8, 8, 0.3) 20%,
        rgba(8, 8, 8, 0.5) 40%,
        rgba(8, 8, 8, 0.5) 60%,
        rgba(8, 8, 8, 0.3) 80%,
        rgba(9,9,9,0.1) 90%,
        rgba(9,9,9,0) 99%);
}

.innerMintContainer{
    width: 100%;

}

.innerMintContainer p{
    color: white;
    font-size: 30px;
    font-weight: 700;
}

.innerMintContainer img{
    width: 30%;
    cursor: pointer;
}

.innerMintContainer{
    display: grid;
    width: 100%;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.image-header video{
    width: 100%; 
    height: auto; 
    background-size: cover;
    overflow: hidden;
    top:0;
    left:0;
}

.image-header img{
    width:100%;
    height:auto;
}

.clubggButton{
    position: relative;
    justify-content: center;
    display: flex;
    margin-bottom: 80px;
}

.page-sections-mint-mobile{
    display: none;
}

@media screen and (max-width:650px) {
    .img-container-overlay{
        height: 5%;
        width: 100%;
        /* background-image: linear-gradient(180deg, rgba(117, 0, 0, 0) 50%, rgba(117, 0, 0, 0.4) 50%);     */
        position: absolute;
        z-index: 10;
        padding-left: 25%;
    } 

    .freeMint{
        padding-top: 15px;
        height: 60px;
        width: 66%;
    }
    
    .page-sections-mint{
        height: 100%;
        width: auto;
        display: none;
    }
    
    .page-sections-mint-mobile{
        width: auto;
        height:  auto;
        display: flex;
    }

    .innerMintContainer img{
        width: 90%;
        margin-bottom: 50px;
    }

    .right{
        flex: 50%;
        display: none;
    }
    
    .right-mobile{
        display: block;
        flex: 50%;
    }
}

@media screen and (max-width:1850px) and (min-width:1700px){
    .img-container-overlay{
        height: 5%;
        width: 73% !important;
        position: absolute;
        z-index: 10;
    } 

    .freeMint{
        padding-top: 15px;
        height: 80px;
    }
}

@media screen and (max-width:1700px) and (min-width:1150px){
    .img-container-overlay{
        height: 5%;
        width: 78% !important;
    } 

    .freeMint{
        padding-top: 15px;
        height: 80px;
    }
}

@media screen and (max-width:1500px) and (min-width:1050px){
    .img-container-overlay{
        height: 5%;
        width: 82% !important;
    } 

    .freeMint{
        padding-top: 15px;
        height: 80px;
    }
}

@media screen and (max-width:1050px) and (min-width:650px){
    .img-container{
        padding-bottom: 50px;

    }
}
