@font-face {
  font-family: 'poker';
  src: local("poker"),
  url("../../fonts/PokerKingsRegular-36K8.ttf") format("truetype");
    /* url("../../fonts/SpaceQuestItalic-60Rx.ttf") format("truetype"); */
}

/* .nav-container{
  width: 100%;
  position: absolute;
} */


nav {
  /* padding: 10px; */
  display: flex;
  justify-content: space-between;
  background-color: rgb(10, 10, 10);
  color: #fff;
  position: relative;
  width: 100%;
  z-index: 4 !important;
  font-size: 1.2rem;
  flex-wrap: nowrap !important;
  padding-right: 1EM;
  border-bottom: solid #505050; 
}


/* .nav-overlay {
  color: #fff;
  position: relative;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.7);
} */

.menu-holder{
  position: relative;
  right: 0;
}

.fas {
  cursor: pointer;
}

.menu-icon {
  display: none;
  font-size: 20px;
}
.menu-icon .fa-times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
}

.menu-icon .fa-bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.logo {
  height: 60px;
  width: 100%;
  color: rgb(255, 255, 255);
  /* font-family: "poker"; */
  font-family: 'Roboto', sans-serif;
  padding-left: 2em;
  margin-top: 5px;
}

.logo img{
  height: 100%;
  width: auto;
  padding-bottom: 5px;
}

.menu-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  margin-top: 15px;
  text-shadow: none;
  z-index: 5;
  opacity: 0.9 !important;
}

.menu-list li{
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.menu-list.close{
  color: #fff;
  text-shadow: none;
  opacity: 1;
}

/* .menu-list li:last-child a {
  font-weight: 300;
  padding: 5px 10px;
  background-color: #cd0101;
  transition: all 0.5s ease-in-out;
  margin-left: 20px;
  border-radius: 10px;
} */

.menu-list li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-weight: 600;
  padding: 5px 10px;
  margin-left: 20px;
  /* font-family: "poker"; */
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  border-radius: 10px;
}

.menu-list li a:hover,
.menu-list li a.active {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  color: #000;
  transition: all 0.5s ease-in-out;
  opacity: 1 !important;
}

@media screen and (max-width: 1200px) {
  .logo {
    font-size: 20px;
    padding-top: 0.6em;
  }
  .menu-list li a {
    font-size: 14px;
    margin-left: 10px;
  }
  .menu-list{
    
  }
  .nav{
    z-index: 5 !important;
  }
  
  .img-container img{
    width: 75% !important;
  }

  .logo h1{
    font-size: 25px;
  }
}

@media screen and (max-width: 850px) {
  .menu-icon {
    display: block;
    margin-top: 20px;
  }
  .menu-list {
    flex-direction: column;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 1);
    padding: 20px;
    right: 0;
    top: 50px;
    transition: all 0.5s ease-in-out;
    border-bottom: #505050 solid;
  }

  .menu-list li {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
  }

  .menu-list li a,
  .menu-list li:last-child a {
    width: 150px;
    display: block;
    margin: auto;
  }

  .menu-list.close {
    top: -600%;
    transition: all 0.5s ease-in-out;
  }
}

@media screen and (max-height: 550px) {
  .nav{
    height: 50px;
  }

  .logo h1{
    font-size: 30px;  
  }

  .menu-list{
    margin-top: 5px;
  }

  .menu-list li a {
    font-size: 15px;
  }

  .MinterButton{
    font-size: 10px;
    width: 25vh;
  }
}