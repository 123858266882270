.logo-container{

}

.fadeAboveFooter{
    font-size: 50px;
    margin: 0 auto;
    font-weight: 400;
    color: #f1f1f1;
    /* font-family: 'poker'; */
    /* font-family:  Verdana, sans-serif; */
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0), #1a1a1a);
    height: 50px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.fadeAboveFooter2{
    background: linear-gradient(rgba(0, 0, 0, 0), #1a1a1a);
    height: 50px;
    width: 100%;
}

.footer{
    /* margin-top: 10vh; */
    /* height:100vh; */
    width: 200vh;
    margin-top: 50vh;
}

.col2 a{
    text-decoration: none;
    color: inherit;
}

.disclaimer{
    width: 100%;
    height: 50px;
    background-color: rgb(37, 37, 37);
    position: relative;
    text-align: center;
    color: white;
}

.partnerBanner{
    width: 100%;
    height: 220px;
    background-color: rgb(117, 0, 0);
    position: relative;
    text-align: center;
    color: white;
    box-shadow: 
    inset 0px 11px 20px -10px rgb(37, 37, 37),
    inset 0px -11px 20px -10px #1a1a1a; 
}

.partnersTitle{
    height:20%;
    text-align: center;
    padding-top: 5px;

}

.partnersTitle h1{
    color: white;
    font-size: 30px;
}

.partnerLogos{
    height: 80%;
    display: flex;
}

.parnerLeft{
    flex:50%;
}

.parnerLeft img{
    height: 90%;
    width: auto;
    cursor: pointer;
}

.parnerRight{
    flex:50%;
}

.parnerRight img{
    height: 90%;
    width: auto;
    cursor: pointer;
}

.background-footer{
    flex: 1;
    width: 100%;
    /* z-index: 4; */
    background: url("") no-repeat;
    background-size: cover;
    position: absolute;
}

.backgorund-footer-overlay{
    /* z-index: 4; */
    height: 100%;
    width: 100%;
    background-color: #1a1a1a;
    ;
    display: flex;
    flex-wrap: wrap;
}

.footer-text{
    color: white;
    text-align: center;
    margin-left: 40vh;
    margin-top: 10vh;
    width:50vh;  
}

.footer-text h1{
    margin-bottom: 5vh;
    font-size: 40px;
}

.footer-text p{
    margin-bottom: 5vh;
    font-size: 20px;
}

/* .discord-box{
    background: rgb(4, 4, 58);
    width: 30vh;
    height: 6vh;
    margin: auto;
    font-size: 30px;
    padding-top: 10px;
} */

.discord-box a{
    color: white;
    text-decoration:none;
}

.footer-bottom{
    background: #1a1a1a;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding-top: 3em;
    padding-bottom: 3em;
}

.col1{
    flex: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.col1 p{
    font-size: 15px;
}

.col2{
    flex: 30%;
    margin: auto;
    display: block;
    align-items: center;
    justify-content: center;
}

.col2 p{
    color: white;
}

.col3{
    flex: 30%;
    margin: auto;
    display: block;
    align-items: center;
    justify-content: center;
}

/* .logo-container{
    width: 40vh;
    height: 20vh;
}

.logo-container img{
    max-width: 100%;
    max-height: 100%;
} */

.copyright{
    color: white;
}

.villians{
    color: white;
}

.nav-column{   
    margin-top: 10px;
}

.footer-links{
    color: white;
    text-decoration: none;
    margin-top: 10px;
    font-size: 20px;
}

.social-row-footer{
    margin-left: 50px;
    font-size: 50px;
}

.socials-col1{
    color: white;
    font-size: 20px;
    padding-left: 5px;
    padding-bottom: 1em;
}

.socials-col2{
    display: flex;
}

.link{
    font-size: 50px;
    margin-right: 20px;
}

.footer-info{
    display : block;
}

.footer-info p{
    color: white;
    padding-left: 30px;
}

.footer-logo-container{
    height: auto;
    width: 100px;
    display: inline-block;
}

.footer-logo{

}

.footer-logo img{
    width: 100%;
    height:100%;
}

@media screen and (max-width:640px) {

    .footer-text{
        margin-left: 10px;
    }
    .background-footer{
        background: none;
    }
    .footer-bottom{
        float: left;
        padding-top: 5vh !important;
        display: block;
        justify-content: center;
        text-align:center;
        width: 100%;
    }
    .col1{
        display:block !important;
        width: 100% !important;
    }
    .socials-col2{
        justify-content: center;
        width: 100% !important;
        overflow-x: hidden !important;
    }
    .disclaimer{
        padding-left: 5px;
        padding-right: 5px;
        height: 60px;
    }
    .partnersTitle h1{
        font-size: 20px;
    }
    .parnerLeft{
        flex: 50%;
    }

    .parnerLeft img{
        height: 80%;
        width: auto;
        cursor: pointer;
        width: 180px;

    }

    .parnerRight{
        flex:50%;
    }
    
    .parnerRight img{
        height: 80%;
        width: auto;
        cursor: pointer;
        width: 150px;
    }

    .partnerBanner{
        height: 150px;
    }

}