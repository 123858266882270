.table-container{
   /* padding: 3em; 
   padding-right: 5vw; */
   border: #505050 solid;
   border-radius: 15px;
   margin-bottom: 3em;
   margin-right: 3em;
   margin-left: 3em;
}

.table-container h1{
    /* font-family: 'poker'; */
    font-family:  Verdana, sans-serif;
    color: white; 
    text-align: center;
    margin-bottom: 20px;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 30px;
    padding: 10px;

}

.timeTable{
    padding-left: 1em;
    padding-right: 1em;
}

.leaderboards-container{
    padding-right:10em;
    padding-left: 10em;
    padding-bottom: 10em;
}

.assign-memebrship-text{
    /* padding:3em; */
    border: #505050 solid;
    border-radius: 15px;
    margin-bottom: 5vh;
}

.assign-memebrship-text em{
    font-family: 'Roboto', sans-serif;
    color: rgb(201, 3, 3);
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
}

.assign-memebrship-text h1{
    /* font-family: 'poker'; */
    font-family:  Verdana, sans-serif;
    color: white; 
    text-align: center;
    margin-bottom: 50px;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 30px;
    padding: 10px;
}

.assign-memebrship-text p{
    text-align: center;
    /* font-family: 'poker'; */
    font-family:  Verdana, sans-serif;
    font-size: 20px;
    font-weight: 400;
    /* padding-left: 10vh; */
    color: white;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
}

.mebershipSearch{
    /* border: 1px solid black; */
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.uploadMembership{
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.membershipNumber{
    border: solid #505050; 
    border-radius: 5px;
    background-color: white;
    width: 15vh;
    height: auto;
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    margin-left: 2vw;
}

.usernameInput{
    border: solid #505050; 
    border-radius: 5px;
    width: 10vw;
    height: auto;
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    margin-left: 1vw;
}

.membershipButton{
    font-size: large;
    background-color: #af0000; 
}

.usernameButtons{

}

.buttonText{
    color: white;
}

.row{
    color: white !important;
}

th {
    color: rgb(255, 255, 255);
}

.custom-select{
    padding-right: 20px !important;
}

.topJunksRow{
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5%;
}

.middleJunksRow{
    padding-left: 25%;
    padding-right: 25%;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5%;
}

.junkDescriptionContainer{
    /* width: 300px;
    height: 400px; */
    width: 20vw;
    display: grid;
    /* align-items: center; */
    justify-content: center;
    border-radius: 20px;
    padding: 0.5em;
    border: #505050 solid;
    background-color: #0c0c0c;
}

.background-junks{
    width:100%;
    position: relative;
    min-height: 113vh;
    max-height: 113vh;
    background-image: url("./../../images/planets balls.jpg"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
}

@media screen and (max-width: 655px){
    .junkDescriptionContainer{
        width: 85%;
    }
}

.backgroundExtender{
    height: 100vh;
    background-color: black;
}

.junkDescriptionContainer{
    position: relative;
    z-index: 1;
}

.junkDescriptionContainer p{
    color: white;
    text-align: center;
    padding-top: 15px;
}
.junkDescriptionContainer h2{
    color: white;
    text-align: center;
    font-size: 25px;
}
.junkDescriptionContainer img{
    width: 100%;
}

.mobile-junk-row{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.backgroundExtenderMeatJunks{
    height: 113vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1.2vh) * 100);
    background-color: #000000;
}

@media screen and (max-width:655px) {
    .blackBackground{
        height: fit-content;
        min-height: 100vh;
        background-color: #000000 !important;
        /* overflow: auto; */
    }    

    .backgroundExtenderMeatJunks{
        display: none;
    }
}


@media screen and (max-height: 650px) and (max-width: 655px){
    .backgroundExtenderMeatJunks{
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 6.5vh) * 100);
        background-color: #000000;
    }
}

@media screen and (min-height: 650px) and (max-height: 700px) and (max-width: 655px){
    .backgroundExtenderMeatJunks{
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 5.75vh) * 100);
        background-color: #000000;
    }
}

@media screen and (min-height: 700px) and (max-height: 750px) and (max-width: 655px){
    .backgroundExtenderMeatJunks{
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 5.25vh) * 100);
        background-color: #000000;
    }
}

@media screen and (min-height: 750px) and (max-height: 800px) and (max-width: 655px){
    .backgroundExtenderMeatJunks{
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 4.75vh) * 100);
        background-color: #000000;
    }
}

@media screen and (min-height: 800px) and (max-height: 850px) and (max-width: 655px){
    .backgroundExtenderMeatJunks{
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 4.25vh) * 100);
        background-color: #000000;
    }
}

@media screen and (min-height: 850px) and (max-height: 900px) and (max-width: 655px){
    .backgroundExtenderMeatJunks{
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 4.25vh) * 100);
        background-color: #000000;
    }
}

@media screen and (min-height: 900px) and (max-height: 950px) and (max-width: 655px){
    .backgroundExtenderMeatJunks{
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 4vh) * 100);
        background-color: #000000;
    }
}


.page-contents-pc{
    width: auto;
    height:  auto;
    display: block;
    position: relative;
    background-color: #000000;
}

.page-contents-mobile{
    width: auto;
    height:  auto;
    display: none;
}

.topHalf{
    flex:50%
}

.bottomHalf{
    flex:50%
}

.blackBackground{
    height: fit-content;
    /* min-height: 399vh;
    background-color: black; */
}

.bottomBlackBackground{
    background-color: #000000;
    height: 50vh;
}

:root{
    background-color: #000000;
}

@media screen and (max-width: 1200px) and (min-width:655px) {
    .title{
        font-size: 35px;
    }

    .junkDescriptionContainer{
        width: 24vw;
    }

    .junkDescriptionContainer h2{
        font-size: 20px;
    }

    .junkDescriptionContainer p{
        font-size: 12px;
    }

    .junkDescriptionContainer h4{
        font-size: 15px;
    }
}

@media screen and (max-width: 900px) {
    .title{
        font-size: 25px;
    }

    .junkDescriptionContainer h2{
        font-size: 17px;
    }

    .junkDescriptionContainer h4{
        font-size: 13px;
    }
}

@media screen and (max-width: 650px) {

    .topJunksRow{
        flex-wrap: wrap;
    }
    
    .middleJunksRow{
        flex-wrap: wrap;
    }
}
  
@media screen and (max-width: 655px) {
    .table-container{
        margin-bottom: 0.5em !important;
        margin-right: 0.5em !important;
        margin-left: 0.5em !important;
        padding: 0.1em;
    }

    .assign-memebrship-text h1{
        font-size: 20px;
        margin-bottom: 20px;
    }
    
    .assign-memebrship-text p{
        font-size: 15px;
    }

    .table-container h1{
        font-size: 20px;
    }

    .assign-memebrship-text em{
        font-size: 18px;
    }

    .page-contents-pc{
        display: none;
    }
    
    .page-contents-mobile{
        display: block;
    }

    .junkDescriptionContainer{
        margin-bottom: 10%;
        position: relative;
        z-index: 2;
    }

    .fadeAboveFooterJunks{
        /* display: none; */
        z-index: 1;
        position: absolute;
    }
}

@media screen and (max-width: 655px) and (max-height: 700px)and (min-height: 500px){
    .backgroundExtender{
        height: 450vh;
    }
}

@media screen and (max-width: 655px) and (max-height: 800px)and (min-height: 700px){
    .backgroundExtender{
        height: 380vh;
    }
}

@media screen and (max-width: 655px) and (max-height: 950px) and (min-height: 800px){
    .backgroundExtender{
        height: 300vh;
    }
}