.teamTwitter{
    display: flex;
    align-items: center;
    justify-content: center;
}

.junkDescriptionContainer h4{
    margin-top: 20px;
    text-align: center;
    color: white;
    font-size: 20px;
}

.junkDescriptionContainer img{
    border-radius: 20px;
}

.twitterImg{
    height: 40px;
    margin-left: 20px;
    color: white;
}

.blackBackgroundMeatJunks{
    height: fit-content;
    min-height: 20vh;
}

.backgroundExtenderMeatTeam{
    height: 30vh; /* Fallback for browsers that do not support Custom Properties */
    /* height: calc(var(--vh, 1.2vh) * 100); */
    background-color: #000000;
}

.spacer{
    height: 200px;
}

.page-contents-metameat{
    width: auto;
    height:  auto;
    display: block;
    position: relative;
    background-color: #000000;
}

@media screen and (max-width:655px) {
    .blackBackgroundMeatJunks{
        height: fit-content;
        min-height: 100vh;
        background-color: #000000 !important;
        /* overflow: auto; */
    }    

    .backgroundExtenderMeatJunks{
        display: none;
    }
}

