.landingPageBK{
    height: 100vh;
    /* background-color: black; */
    background-image: url("./../../images/junkBk.png"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.landingTitle{
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 100px;
    padding-top: 100px;
}

.landingTitle h1{
    font-size: 60px;
}

.landingText{
    color: white;
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 100px;
    padding-bottom: 100px;
}

.landingImage{
    display: flex;
    /* align-items:center; */
    justify-content: center;
    width: 400px;
    height: 100vh;
}

.imgBox img{
    /* width: 500px;
    height: 400px; */
    width: 600px;
    height: 500px;
    margin-top: 80%;
}

.junkImg{
    height: 90vh;
    /* margin-top: 150px; */
}

.junkImg img{
    width: 100%;
    height: 100%;
}

.imgBox{
    position: absolute;
    cursor: move;
    color: black;
    flex: 40%;
    border-radius: 5px;
    padding: 1em;
    margin: auto;
    user-select: none;
    z-index: 5;
    
    /* margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: center;
    align-items: center; */
}


.box {
  position: absolute;
  top: 0;
  background: rgb(255, 255, 255);
  /* border: 0.25rem solid;
  box-sizing: border-box;
  height: 6rem;
  width: 8rem; 
  outline: 0;*/
  overflow: hidden;
  margin-top: 10px;
  margin-right: 120px;
  width: 100px;
  height: 100px;
  border-radius: 0 50% 50% 50%;
  border: 3px solid black;
  transform: rotate(200deg);
}

.box::before {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box::after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: -10%;
    height: 200%;
    width: 200%;
    box-sizing: border-box;
    background-color: rgb(0, 0, 0);
    border-radius: 40%;
    animation: spin 6s linear infinite;
}

/* @keyframes spin {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(-100%) rotate(360deg);
  }
} */

@keyframes spin {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }


@media screen and (max-width:400px) {
    .imgBox img{
        width: 300px;
        height: unset;
        margin-top: 50vh;
    }

    .landingTitle{
        color: white;
        position: absolute;
        top: 0;
        left: unset;
        right: 0;
        padding-right: 10px;
        padding-top: 10px;
    }
    .landingTitle h1{
        font-size: 35px;
    }
    .box {
        margin-top: 50px;
        margin-right: 25%;
        width: 70px;
        height: 70px;;
    }
    .landingText{
        position: absolute;
        transform: rotate(270deg);
        left: 0;
        bottom: 50%;
        padding-bottom: 75vw;
    }
}

@media screen and (max-width:400px) and (max-height:700px) {
    .imgBox img{
        width: 300px;
        height: unset;
        margin-top: 50vh;
    }

    .landingTitle{
        color: white;
        position: absolute;
        top: 0;
        left: unset;
        right: 0;
        padding-right: 10px;
        padding-top: 10px;
    }
    .landingTitle h1{
        font-size: 35px;
    }
    .box {
        margin-top: 50px;
        margin-right: 20%;
        width: 50px;
        height: 50px;;
    }
}

@media screen and (min-width:1000px) and (max-width:1500px) and (max-height:700px) {
    .box {
        margin-top: 10px;
        margin-right: 7%;
        width: 70px;
        height: 70px;;
    }
}

@media screen and (max-width:650px) and (min-width:401px){
    .imgBox img{
        width: 380px;
        height: unset;
        margin-top: 50vh;
    }
    
    .imgBox{

    }

    .junkImg{
        height: 60vh;
        /* margin-top: 150px; */
    }

    .landingTitle{
        color: white;
        position: absolute;
        top: 0;
        left: unset;
        right: 0;
        padding-right: 10px;
        padding-top: 10px;
    }
    .landingTitle h1{
        font-size: 40px;
    }
    .box {
        margin-right: 120px;
        width: 80px;
        height: 80px;;
    }
    .landingText{
        position: absolute;
        transform: rotate(270deg);
        left: 0;
        bottom: 50%;
        padding-bottom: 75vw;
    }
}

@media screen and (max-width:1150px) and (min-width:750px) and (min-height:950px){
    .imgBox img{
        width: 380px;
        height: unset;
        margin-top: 50vh;
    }
    
    .imgBox{

    }

    .junkImg{
        height: 60vh;
        /* margin-top: 150px; */
    }

    .landingTitle{
        color: white;
        position: absolute;
        top: 0;
        left: unset;
        right: 0;
        padding-right: 10px;
        padding-top: 10px;
    }
    .landingTitle h1{
        font-size: 60px;
    }
    .box {
        margin-right: 120px;
        width: 80px;
        height: 80px;;
    }
    .landingText{
        position: absolute;
        transform: rotate(270deg);
        left: 0;
        bottom: 50%;
        padding-bottom: 75vw;
    }
}

@media screen and (max-width:1850px) and (min-width:1700px){

}

@media screen and (max-width:1700px) and (min-width:1150px){
    .imgBox img{
        width: 500px;
        height: 400px;
        margin-top: 80%;
    }
}

@media screen and (max-width:1500px) and (min-width:1050px){

}

@media screen and (max-width:1150px) and (min-width:650px){
    .imgBox img{
        width: 600px;
        height: 400px;
        margin-top: 50vh;
    }
    
    .imgBox{

    }
}

