.prizeWinnings{
    border: solid #505050;
    border-radius: 5px;
    background-color: white;
    width: 15vh;
    height: 60px;
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    margin-left: 2vw;
}

.page-sections-prize{
    height: 100%;
    width: auto;
}

.payout-mobile{
    display: none;
}

.payout-desktop{
    display: block;
}

.prize-container{
    /* padding: 1em; */
    padding-top: 1em;
    justify-content: center;
    text-align: center;
    /* display: flex; */
    border: solid #505050;
    border-radius: 20px;
    background-color: #1a1a1a;
    margin-bottom: 50px;
}

.prize-container h1{
    /* font-family:  Verdana, sans-serif; */
    font-family: 'Roboto', sans-serif;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    background-color: #1a1a1a;
}

.left-prize{
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    padding-right: 15%;
    padding-left: 15%;
}

.left-prize h1{
    color: white;
    font-size: 30px;
}

.right-prize{
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    padding-right: 13%;
    padding-left: 13%;
}

.claim-prize-text{
    /* padding:3em; */
    border: #505050 solid;
    border-radius: 15px;
    margin-bottom: 3em;
    margin-right: 3em;
    margin-left: 3em;
    padding-bottom: 3em;
    /* margin-top: 60px; */
}

.claim-prize-text h1{
    /* font-family:  Verdana, sans-serif; */
    font-family: 'Roboto', sans-serif;
    color: white;
    text-align: center;
    margin-bottom: 50px;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 30px;
    padding: 10px;
}

.claim-prize-text p{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-weight: 400;
    padding-right: 3em;
    padding-left: 3em;
    color: white;
}

.prizes-search{
    margin-bottom: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.img-container-payouts{
    position: relative;
    justify-content: center;
    display: flex;
    /* margin-bottom: 50px; */
    /* width: 30vw; */
    /* background-color: #1a1a1a; */
    border-radius: 20px;
    padding: 1em;
}

.img-container-payouts img{
    position:relative;
    width: 100%;
    height: auto;
    /* border: solid #505050;  */
    /* border-radius: 20px; */
}

.winningsButton{
    height: 53px;
    font-size: large;
    background-color: #af0000;
}

.roadMapSections{
    display: flex;
    height: 100vh;
}

.roadMapSections-mobile{
    display: none;
}

.middleRoadmap img{
    height: 100%;
}

.leftRoadmap{
    flex:40%;
    padding-left: 1rem;
}
.middleRoadmap{
    flex:20%;
}
.rightRoadmap{
    flex:40%;
    padding-right: 1rem;
    margin-bottom: 5rem;
}

.roadmapFullContainer{
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}


.roadmapBox{
    border: white solid 3px;
    border-radius: 20px;
    padding: 0.5em;
    flex: 70%;
    border: #505050 solid;
    background-color: #0c0c0c;
    position: relative;
    z-index: 4;
    text-align: center;

}

.roadmapBox p{
    color: white;
}

.roadmapBox h3{
    color: white;
    text-align: center;
}

.roadmapBoxFilled{
    border: rgb(255, 255, 255) solid 3px;
    border-radius: 20px;
    padding: 0.5em;
    flex: 70%;
    border: #505050 solid;
    background-color: #e0e0e0;
}

.roadmapBoxFilled p{
    color: rgb(0, 0, 0);
    text-align: center;
}

.roadmapBoxFilled h3{
    color: rgb(0, 0, 0);
    text-align: center;
}

.backgroundExtender-roadmap{
    background-color: black;
    min-height: 120vh;
    display: none;
}

.horizontal_dotted_line_shorter{
    border-bottom: 10px dotted white;
    width: 80%;
    flex: 40%;
    margin-left: 5px;
    margin-right: 5px;
} 


.horizontal_dotted_line{
    border-bottom: 10px dotted white;
    width: 100%;
    flex: 30%;
    margin-left: 5px;
    margin-right: 5px;
} 

.horizontal_dotted_line_shorter_right{
    border-bottom: 10px dotted white;
    width: 80%;
    flex: 40%;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 50px;
} 


.horizontal_dotted_line_right{
    border-bottom: 10px dotted white;
    width: 100%;
    flex: 30%;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 50px;
}

.page-contents-roadmap-pc{
    display: block;
}

.page-contents-roadmap-mobile{
    display: none;
}

@media screen and (max-width:400px) {
    .claim-prize-text p{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

}

@media screen and (max-width:900px) {
    .claim-prize-text h1{
        font-size: 20px !important;
        margin-bottom: 20px;
    }

    .claim-prize-text p{
        font-size: 15px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .left-prize h1{
        font-size: 30px;
    }

    .image-header-mobile{
        display: block !important;
    }

    .image-header-pc{
        display: none;
    }

    .image-header2{
        padding-top: 15PX;
    }

    .roadMapSections{
        display: none;
    }
    
    .roadMapSections-mobile{
        display: grid;
        max-height: 190vh;
    }

    .backgroundExtender-roadmap{
        height: 100%;
        background-color: black;
        display: block;
    }

    .roadmapFullContainer{
        margin-right: 20px;
        margin-left:20px;
    }
}

.backgroundExtender-roadmap{
    background-color: #000000;
}

@media screen and (max-height: 650px) and (max-width: 655px){
    .backgroundExtender-roadmap{
        min-height: unset; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1.4vh) * 100);
    }
}

@media screen and (min-height: 650px) and (max-height: 700px) and (max-width: 655px){
    .backgroundExtender-roadmap{
        min-height: unset; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1.6vh) * 100);
    }
}

@media screen and (min-height: 700px) and (max-height: 750px) and (max-width: 655px){
    .backgroundExtender-roadmap{
        min-height: unset; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 0.5vh) * 100);
    }
}

@media screen and (min-height: 700px) and (max-height: 750px) and (max-width: 400px){
    .backgroundExtender-roadmap{
        min-height: unset; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);
    }
}

@media screen and (min-height: 750px) and (max-height: 800px) and (max-width: 655px){
    .backgroundExtender-roadmap{
        min-height: unset; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 0.5vh) * 100);
    }
}

@media screen and (min-height: 750px) and (max-height: 800px) and (max-width: 400px){
    .backgroundExtender-roadmap{
        min-height: unset; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 0.9vh) * 100);
    }
}

@media screen and (min-height: 800px) and (max-height: 850px) and (max-width: 655px){
    .backgroundExtender-roadmap{
        min-height: unset; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 0.75vh) * 100);
    }
}

@media screen and (min-height: 850px) and (max-height: 900px) and (max-width: 655px){
    .backgroundExtender-roadmap{
        min-height: unset; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 0.5vh) * 100);
    }
}

@media screen and (min-height: 900px) and (max-height: 950px) and (max-width: 655px){
    .backgroundExtender-roadmap{
        min-height: unset; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 0.5vh) * 100);
    }
}

@media screen and (max-width: 900px) {
    .page-contents-roadmap-pc{
        display: none;
    }
    
    .page-contents-roadmap-mobile{
        display: block;
    }
}

@media screen and (max-width: 1800px) and (min-width: 1500px) {
    .roadmapBox{
        border: white solid 3px;
        border-radius: 20px;
        padding: 0.5em;
        flex: 70%;
        border: #505050 solid;
        background-color: #0c0c0c;
    }
    
    .roadmapBox p{
        font-size:  15px;
    }
    
    .roadmapBox h3{
        font-size:  20px;
    }
}

@media screen and (min-width: 1800px) and (max-width: 2000px){
    .roadmapBox{
        flex: 90%;
    }
    
    .roadmapBox p{
        font-size:  18px;
    }
    
    .roadmapBox h3{
        font-size:  22px;
    }

    .roadmapFullContainer{
        margin-bottom: 4%;
    }

    .roadmapBoxFilled{
        flex: 70%;
    }
    
    .roadmapBoxFilled p{
        font-size:  18px;
    }
    
    .roadmapBoxFilled h3{
        font-size:  22px;
    }
    
    /* .horizontal_dotted_line_shorter_right {
        flex: 18%;
    }
    .horizontal_dotted_line_right{
        flex: 25%;
    }
    .horizontal_dotted_line_shorter{
        flex: 25%;
    }
    .horizontal_dotted_line {
        flex: 18%;
    } */
}

@media screen and (max-width: 1500px) and (min-width: 1100px) {
    .roadmapBox{
        border: white solid 3px;
        border-radius: 20px;
        padding: 0.4em;
        flex: 90%;
        border: #505050 solid;
        background-color: #0c0c0c;
    }
    
    .roadmapBox p{
        font-size:  13px;
    }
    
    .roadmapBox h3{
        font-size:  17px;
    }

    .roadmapFullContainer{
        margin-bottom: 5%;
    }

    .roadmapBoxFilled{
        border: white solid 3px;
        border-radius: 20px;
        padding: 0.4em;
        flex: 70%;
        border: #505050 solid;
        background-color: #ffffff;
    }
    
    .roadmapBoxFilled p{
        font-size:  13px;
        color: rgb(0, 0, 0);
    }
    
    .roadmapBoxFilled h3{
        font-size:  17px;
        color: rgb(0, 0, 0);
    }
    
    .horizontal_dotted_line_shorter_right {
        flex: 18%;
    }
    .horizontal_dotted_line_right{
        flex: 25%;
    }
    .horizontal_dotted_line_shorter{
        flex: 25%;
    }
    .horizontal_dotted_line {
        flex: 18%;
    }
}

@media screen and (max-height: 670px) and (min-width: 1100px) and (max-width: 1500px){
    .roadmapBox{
        border: white solid 3px;
        border-radius: 20px;
        padding: 0.4em;
        flex: 90%;
        border: #505050 solid;
        background-color: #0c0c0c;
    }
    
    .roadmapBox p{
        font-size:  13px;
    }
    
    .roadmapBox h3{
        font-size:  17px;
    }

    .roadmapFullContainer{
        margin-bottom: 1%;
    }

    .roadmapBoxFilled{
        border: white solid 3px;
        border-radius: 20px;
        padding: 0.4em;
        flex: 70%;
        border: #505050 solid;
        background-color: #ffffff;
    }
    
    .roadmapBoxFilled p{
        font-size:  13px;
        color: rgb(0, 0, 0);
    }
    
    .roadmapBoxFilled h3{
        font-size:  17px;
        color: rgb(0, 0, 0);
    }
    
    .horizontal_dotted_line_shorter_right {
        flex: 18%;
    }
    .horizontal_dotted_line_right{
        flex: 25%;
    }
    .horizontal_dotted_line_shorter{
        flex: 25%;
    }
    .horizontal_dotted_line {
        flex: 18%;
    }
}

@media screen and (max-height: 600px) and (min-width: 1100px) and (max-width: 1500px){
    .roadmapBox{
        border: white solid 3px;
        border-radius: 20px;
        padding: 0.4em;
        flex: 90%;
        border: #505050 solid;
        background-color: #0c0c0c;
    }
    
    .roadmapBox p{
        font-size:  12px;
    }
    
    .roadmapBox h3{
        font-size:  15px;
    }

    .roadmapFullContainer{
        margin-bottom: 1%;
    }

    .roadmapBoxFilled{
        border: white solid 3px;
        border-radius: 20px;
        padding: 0.4em;
        flex: 70%;
        border: #505050 solid;
        background-color: #ffffff;
    }
    
    .roadmapBoxFilled p{
        font-size:  13px;
        color: rgb(0, 0, 0);
    }
    
    .roadmapBoxFilled h3{
        font-size:  17px;
        color: rgb(0, 0, 0);
    }
    
    .horizontal_dotted_line_shorter_right {
        flex: 18%;
    }
    .horizontal_dotted_line_right{
        flex: 25%;
    }
    .horizontal_dotted_line_shorter{
        flex: 25%;
    }
    .horizontal_dotted_line {
        flex: 18%;
    }
}

@media screen and (max-height: 600px) and (min-width: 900px) and (max-width: 1100px){
    .roadmapBox{
        border: white solid 3px;
        border-radius: 20px;
        padding: 0.4em;
        flex: 90%;
        border: #505050 solid;
        background-color: #0c0c0c;
    }
    
    .roadmapBox p{
        font-size:  9px;
        margin-bottom: 0;
    }
    
    .roadmapBox h3{
        font-size:  12px !important;
    }

    .roadmapFullContainer{
        margin-bottom: 1%;
    }

    .roadmapBoxFilled{
        z-index: 100;
    }
    
    .roadmapBoxFilled p{
        font-size:  9px;
        color: rgb(0, 0, 0);
    }
    
    .roadmapBoxFilled h3{
        font-size:  12px !important;
        color: rgb(0, 0, 0);
    }
    
    .horizontal_dotted_line_shorter_right {
        flex: 18%;
    }
    .horizontal_dotted_line_right{
        flex: 25%;
    }
    .horizontal_dotted_line_shorter{
        flex: 25%;
    }
    .horizontal_dotted_line {
        flex: 18%;
    }
}

@media screen and (max-height: 870px) and (min-height: 760px) and (min-width: 1500px) and (max-width: 2000px){

    .roadmapFullContainer{
        margin-bottom: 4%;
    }

    .roadmapBoxFilled{
        flex: 70%;
    }
    
    .roadmapBoxFilled p{
        color: rgb(0, 0, 0);
    }
    
    .roadmapBoxFilled h3{
        font-size: 20px;
        color: rgb(0, 0, 0);
    }
    
    /* .horizontal_dotted_line_shorter_right {
        flex: 18%;
    }
    .horizontal_dotted_line_right{
        flex: 25%;
    }
    .horizontal_dotted_line_shorter{
        flex: 25%;
    }
    .horizontal_dotted_line {
        flex: 18%;
    } */
}

@media screen and (max-height: 760px) and (min-height: 670px) and (min-width: 1500px) and (max-width: 2000px){

    .roadmapFullContainer{
        margin-bottom: 2%;
    }

    .roadmapBoxFilled{
        flex: 70%;
    }

    .roadmapBox p{
        font-size: 15px !important;
    }

    .roadmapBox h3{
        font-size: 18px !important;
    }
    
    .roadmapBoxFilled p{
        color: rgb(0, 0, 0);
    }
    
    .roadmapBoxFilled h3{
        font-size: 20px;
        color: rgb(0, 0, 0);
    }
    
    .horizontal_dotted_line_shorter_right {
        flex: 18%;
    }
    .horizontal_dotted_line_right{
        flex: 25%;
    }
    .horizontal_dotted_line_shorter{
        flex: 25%;
    }
    .horizontal_dotted_line {
        flex: 18%;
    }
}

@media screen and (max-height: 670px) and (min-width: 1500px) and (max-width: 2000px){

    .roadmapFullContainer{
        margin-bottom: 1%;
    }

    .roadmapBoxFilled{
        flex: 70%;
    }
    
    .roadmapBoxFilled p{
        color: rgb(0, 0, 0);
    }
    
    .roadmapBoxFilled h3{
        font-size: 20px;
        color: rgb(0, 0, 0);
    }
    
    .horizontal_dotted_line_shorter_right {
        flex: 18%;
    }
    .horizontal_dotted_line_right{
        flex: 25%;
    }
    .horizontal_dotted_line_shorter{
        flex: 25%;
    }
    .horizontal_dotted_line {
        flex: 18%;
    }
}

@media screen and (max-height: 900px) and (min-height: 500px) and (min-width: 800px) and (max-width: 1100px){

    .roadmapFullContainer{
        margin-bottom: 2%;
    }

    .roadmapBox p{
        font-size:  12px;
    }
    
    .roadmapBox h3{
        font-size:  15px;
    }

    .roadmapFullContainer{
        margin-bottom: 1%;
    }
    
    .roadmapBoxFilled p{
        font-size:  13px;
    }
    
    .roadmapBoxFilled h3{
        font-size:  17px;
    }
    
    .horizontal_dotted_line_shorter_right {
        flex: 18%;
    }
    .horizontal_dotted_line_right{
        flex: 25%;
    }
    .horizontal_dotted_line_shorter{
        flex: 25%;
    }
    .horizontal_dotted_line {
        flex: 18%;
    }
}

@media screen and (min-height: 900px) and (min-width: 650px) and (max-width: 1100px){

    .roadmapFullContainer{
        margin-bottom: 2%;
    }

    .roadmapBox p{
        font-size:  15px;
    }
    
    .roadmapBox h3{
        font-size:  20px;
    }

    .roadmapFullContainer{
        margin-bottom: 14%;
    }
    
    .roadmapBoxFilled p{
        font-size:  15px;
    }
    
    .roadmapBoxFilled h3{
        font-size:  20px;
    }
    
    .horizontal_dotted_line_shorter_right {
        flex: 18%;
        display: none !important;
    }
    .horizontal_dotted_line_right{
        flex: 25%;
        display: none !important;
    }
    .horizontal_dotted_line_shorter{
        flex: 25%;
        display: none !important;
    }
    .horizontal_dotted_line {
        flex: 18%;
        display: none !important;
    }
    .middleRoadmap{
        display: none !important;
    }
    .leftRoadmap{
        margin-top: 50px;
    }
}

.image-header2{
    width:100%;
    /* height:250px; */
}

.image-header2 img{
    width:100%;
    height:auto;
}

.image-header-mobile{
    display: none;
}
